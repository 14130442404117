const comic1Images = [
  {
    original: '/images/comic1/page1.jpg',
    thumbnail: '/images/comic1/thumb1.jpg',
    description: 'Comic 1 - Page 1',
    originalAlt: 'Comic 1 - Page 1',
  },
  {
    original: '/images/comic1/page2.jpg',
    thumbnail: '/images/comic1/thumb2.jpg',
    description: 'Comic 1 - Page 2',
    originalAlt: 'Comic 1 - Page 2',
  },
    {
    original: '/images/comic1/page3.jpg',
    thumbnail: '/images/comic1/thumb3.jpg',
    description: 'Comic 1 - Page 3',
    originalAlt: 'Comic 1 - Page 3',
  },
    {
    original: '/images/comic1/page4.jpg',
    thumbnail: '/images/comic1/thumb4.jpg',
    description: 'Comic 1 - Page 4',
    originalAlt: 'Comic 1 - Page 4',
  },
  // Add more images as needed
];

export default comic1Images;
